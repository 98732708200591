<template>
  <div
    id="transfer"
    class="px-1"
  >
    <Toolbar>
      <div slot="btn">
        <v-btn
          icon
          @click="fetchWallet"
        >
          <Icon
            data="@icon/fetch.svg"
            width="24px"
            height="24px"
            class="title--text"
          />
        </v-btn>
      </div>
    </Toolbar>
    <v-container class="pt-56">
      <v-row>
        <v-col
          cols="12"
          class="bg p-sticky top-56"
        >
          <WalletTemplate3 />
        </v-col>
        <!-- 免轉模式 -->
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-divider class="divider" />
          <v-container>
            <v-row>
              <v-col
                cols="6"
                class="d-flex align-center px-0"
              >
                <div class="d-flex align-center">
                  <!-- 切換轉換模式 -->
                  <label
                    for="mode"
                    class="subtitle-2 title--text font-weight-medium"
                  >
                    {{ $t('myCenter.automaticMode') }}
                  </label>
                  <v-switch
                    id="mode"
                    v-model="walletModeTrueFalse"
                    color="primary"
                    hide-details
                    class="pa-0 ma-0 ml-4"
                  />

                  <!-- loading allRetrieve -->
                  <v-progress-circular
                    v-show="loading.allRetrieve"
                    size="24"
                    width="2"
                    indeterminate
                    color="primary"
                  />
                </div>
              </v-col>

              <v-col
                cols="6"
                class="d-flex align-center justify-end px-0"
              >
                <!-- 全部回收 -->
                <v-btn
                  v-if="false"
                  :disabled="!hasBalanceCanRetrieve"
                  text
                  color="primary"
                  height="36"
                  width="79"
                  @click="openRetrieveAllWalletBalanceDialog"
                >
                  <span class="font-weight-medium">
                    {{ $t('myCenter.recycleAll') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="divider" />
        </v-col>

        <!-- wallet loading 錢包已取得 -->
        <template v-if="walletLoading">
          <!-- 轉換金額區塊 -->
          <v-col
            v-show="!walletModeTrueFalse"
            cols="12"
            class="pb-4 pt-0"
          >
            <v-container>
              <v-row
                id="transfer-area"
                class="mb-1"
              >
                <v-col
                  cols="5"
                  class="pt-2 px-0 pb-0"
                  @click="openSelectListDialog('from')"
                >
                  <!-- 轉出 -->
                  <v-text-field
                    v-model="inputDisplay.from"
                    class="pb-2"
                    filled
                    outlined
                    readonly
                    dense
                    max-height="36"
                    append-icon="mdi-chevron-right"
                    hide-details
                  />
                  <!-- 轉出金額 (顯示) -->
                  <span
                    class="wallet-balance caption primary--text"
                  >
                    {{ currencySymbol_ + walletBalance.from }}
                  </span>
                </v-col>

                <v-col
                  cols="2"
                  class="pt-2 px-0 d-flex align-start justify-center"
                >
                  <v-btn
                    class="chang__wallet"
                    icon
                    @click="changWallet"
                  >
                    <Icon
                      data="@icon/arrow__right.svg"
                      width="16"
                      height="8"
                      class="primary--text"
                    />
                  </v-btn>
                </v-col>

                <v-col
                  cols="5"
                  class="pt-2 px-0 pb-0"
                  @click="openSelectListDialog('to')"
                >
                  <!-- 轉入 -->
                  <v-text-field
                    v-model="inputDisplay.to"
                    class="pb-2"
                    hide-details
                    filled
                    readonly
                    outlined
                    max-height="36"
                    dense
                    append-icon="mdi-chevron-right"
                  />
                  <span
                    class="wallet-balance caption primary--text"
                  >
                    {{ currencySymbol_ + (walletBalance.to === 0 ? '0.00' : walletBalance.to) }}
                  </span>
                </v-col>
              </v-row>

              <v-form
                ref="form"
                v-model="valid"
              >
                <v-row>
                  <v-col
                    id="transfer__amount"
                    cols="12"
                    class="px-0 pr-1 d-flex pb-1"
                  >
                    <!-- 輸入金額 -->
                    <v-text-field
                      v-model.trim.number="form.amount"
                      onkeyup="this.value=this.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                      type="number"
                      outlined
                      max-height="36"
                      dense
                      single-line
                      :rules="[rules.required, rules.overZero, rules.overMaxBalance]"
                      :placeholder="$t('form.missAmount')"
                      inputmode="decimal"
                    >
                      <div slot="append">
                        <!-- 梭哈 -->
                        <v-btn
                          outlined
                          color="primary"
                          class="showHand__btn"
                          dark
                          @click="form.amount = walletBalance.from"
                        >
                          <span class="primary--text caption">
                            {{ $t('global.showHand') }}
                          </span>
                        </v-btn>
                      </div>
                    </v-text-field>
                  </v-col>

                  <!-- 額度轉換 -->
                  <v-col
                    cols="12"
                    class="px-0 pt-0"
                  >
                    <v-btn
                      :loading="loading.transferBtn"
                      :disabled="!valid"
                      block
                      color="primary"
                      @click="walletTransfer(form)"
                    >
                      <span class="btnText--text">
                        {{ $t('global.transfer') }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-divider class="divider" />
          </v-col>

          <!-- 場館錢包 -->
          <v-col
            cols="12"
            class="pt-0"
          >
            <div>
              <span class="subtitle-2 title--text font-weight-mudium">
                {{ $t('global.vendor') + $t('global.wallet') }}
              </span>
            </div>

            <v-container class="mt-3">
              <v-row>
                <!-- 全部(任一錢包有錢) -->
                <v-col
                  v-show="hasBalanceCanRetrieve"
                  cols="12"
                  class="px-1 py-1"
                >
                  <!-- 錢包名稱(全部) -->
                  <v-card
                    class="d-flex align-center justify-center flex-column"
                    height="74"
                    :color="transferWallertAll ? 'secondary' : 'card1'"
                    :disabled="walletBalanceTotal < 0.01"
                    @click="recycleWalletBtn"
                  >
                    <div class="line-height-12">
                      <span
                        class="caption font-weight-bold"
                        :class="transferWallertAll ? 'black--text' : 'subtitle--text'"
                      >
                        {{ $t('global.all') }}
                      </span>
                    </div>

                    <!-- 金額 -->
                    <div class="line-height-16">
                      <span
                        class="subtitle-2"
                        :class="transferWallertAll ? 'black--text' : 'primary--text'"
                      >
                        {{ currencySymbol_ + moneySpec(Number(walletBalanceTotal)) }}
                      </span>
                    </div>
                  </v-card>
                </v-col>

                <v-col
                  v-for="(game, index) in walletList"
                  :key="game.wallet_code"
                  cols="6"
                  class="px-1 py-1"
                >
                  <!-- 所有錢包 -->
                  <v-card
                    class="p-relative"
                    height="74"
                    :color="activeWallet[game.wallet_code] ? 'secondary' : 'card1' "
                    :class="game.balance > 0 ? 'cursor-pointer' : '' "
                    :disabled="game.balance < 0.01"
                    @click="ToggleAddToTransferWallertList(game.wallet_code, game.balance, index)"
                  >
                    <!-- 錢包內容 -->
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-column align-center justify-center h-74 px-0"
                        >
                          <!-- 錢包名稱 -->
                          <div class="line-height-12">
                            <span
                              class="caption font-weight-bold"
                              :class="activeWallet[game.wallet_code] ? 'black--text' : 'subtitle--text'"
                            >
                              {{ game.wallet.name }}
                            </span>
                          </div>

                          <!-- 金額 -->
                          <div class="line-height-16">
                            <span
                              class="subtitle-2 font-weight-bold"
                              :class="[!activeWallet[game.wallet_code] && game.balance > 0 ? 'primary--text' : 'subtitle--text', { 'black--text': activeWallet[game.wallet_code]} ] "
                            >
                              {{ currencySymbol_ + moneySpec(Number(game.balance)) }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-if="transferWalletList.length > 0"
                class="p-sticky bottom-0 bg"
              >
                <v-col
                  cols="5"
                  class="px-1"
                >
                  <!-- 取消選取 -->
                  <v-btn
                    depressed
                    block
                    color="card1"
                    height="44"
                    @click="clearSeleted"
                  >
                    <span class="title--text subtitle-2 font-weight-medium">
                      {{ $t('global.deselect') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="7"
                  class="px-1"
                >
                  <!-- 批次回收 -->
                  <v-btn
                    depressed
                    block
                    color="primary"
                    height="44"
                    @click="openMutiRetrieveDialog"
                  >
                    <span class="btnText--text subtitle-2 font-weight-medium">
                      {{ $t('myCenter.batchRecycle') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- 全部回收 -->
              <v-row
                v-show="transferWallertAll"
                class="p-sticky bottom-0 bg"
              >
                <v-col
                  cols="6"
                  class="px-1"
                >
                  <!-- 取消選取 -->
                  <v-btn
                    depressed
                    block
                    color="card1"
                    height="44"
                    @click="cancelRecycleWalletBtn"
                  >
                    <span class="title--text subtitle-1 font-weight-medium">
                      {{ $t('global.deselect') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  class="px-1"
                >
                  <!-- (全部)回收 -->
                  <v-btn
                    depressed
                    block
                    color="primary"
                    height="44"
                    @click="openRetrieveAllWalletBalanceDialog"
                  >
                    <span class="btnText--text subtitle-1 font-weight-medium">
                      {{ $t('myCenter.recycle') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </template>

        <!-- wallet loading 錢包取得中 -->
        <template
          v-else
          class="text-center"
        >
          <v-col class="text-center mt-10">
            <v-progress-circular
              :size="70"
              :width="4"
              color="primary"
              indeterminate
            />
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- 選擇 轉換錢包 彈窗 -->
    <SelectList :status="selectDialog.status">
      <v-row
        slot="title"
        class="p-sticky bg top-0 px-3 text-h6 title--text font-weight-medium"
      >
        <v-col class="">
          {{ $t('myCenter.selectVendorWallet') }}
        </v-col>
      </v-row>

      <!-- LIST -->
      <v-row slot="list">
        <!-- 中心錢包 -->
        <v-col
          cols="12"
          :class="[
            {
              secondary:
                form[`${selectDialog.type}_walletcode`] === 'player_balance',
            },
            'rounded'
          ]"
          @click="
            setWalletCode({
              walletCode: 'player_balance',
              name: $t('myCenter.mainWallet'),
              type: selectDialog.type,
            })
          "
        >
          <div class="px-3">
            {{ $t('myCenter.mainWallet') }}
          </div>
        </v-col>
        <!-- LOOP API 錢包 -->
        <v-col
          v-for="game in selectDialog.list"
          :key="game.wallet_code"
          cols="12"
          :class="[
            {
              secondary:
                form[`${selectDialog.type}_walletcode`] === game.wallet_code,
            },
            'rounded'
          ]"
          @click="
            setWalletCode({
              walletCode: game.wallet_code,
              name: game.wallet.name,
              type: selectDialog.type,
            })
          "
        >
          <div class="px-3">
            {{ game.wallet.name }}
          </div>
        </v-col>
      </v-row>
    </SelectList>

    <!-- dialog -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="140"
    >
      <!-- 全部回收 -->
      <template v-if="dialog.type === 'allRetrieve'">
        <div
          slot="text"
          class="px-6"
        >
          {{ $t('flashMessage.allWalletTransferToMainWallet') }}
        </div>

        <v-btn
          slot="btn"
          text
          @click="resetDialog"
        >
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="retrieveAllWalletBalance"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>

      <!-- 批次收回 -->
      <template v-if="dialog.type === 'retrieve'">
        <div
          slot="text"
          class="px-6 text-text"
        >
          {{ $t('flashMessage.recycleAll') }}
        </div>

        <v-btn
          slot="btn"
          text
          @click="resetDialog"
        >
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="mutiRetrieve"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>

      <!-- 是否開啟免轉 (尚有餘額) -->
      <template v-if="dialog.type === 'transfer'">
        <div
          slot="text"
          class="px-6"
        >
          {{ $t('flashMessage.otherWalletsHaveBalances') }}
        </div>

        <v-btn
          slot="btn"
          text
          @click="setUserMode({ wallet_type: 'single' })"
        >
          <span class="subtitle--text">
            {{ $t('global.cancel') }}
          </span>
        </v-btn>
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="resetDialog"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import transferMixin from '../mixins/transferMixin'

export default {
  mixins: [transferMixin],
}
</script>

<style lang="scss" scoped>
.lt-6 {
	top: 3px;
	left: 3px;
}

.h-74 {
	height: 74px;
}

.line-height-12 {
	line-height: 12px;
}

.line-height-16 {
	line-height: 16px;
}

.bottom-0 {
	bottom: 0;
}

.top-0 {
	top: 0;
}

.top-56 {
	top: 56px;
	z-index: 1;
}

.wallet-balance {
	line-height: 12px;
}
</style>
