var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-1", attrs: { id: "transfer" } },
    [
      _c("ToolbarTemplate1", [
        _c(
          "div",
          { attrs: { slot: "btn" }, slot: "btn" },
          [
            _c(
              "v-btn",
              { attrs: { icon: "" }, on: { click: _vm.fetchWallet } },
              [
                _c("Icon", {
                  staticClass: "title--text",
                  attrs: {
                    data: require("@icon/fetch.svg"),
                    width: "24px",
                    height: "24px",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-container",
        { staticClass: "pt-56" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "bg p-sticky top-56", attrs: { cols: "12" } },
                [_c("Wallet")],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _c("v-divider", { staticClass: "divider" }),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center px-0",
                              attrs: { cols: "6" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "subtitle-2 title--text font-weight-medium",
                                      attrs: { for: "mode" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("myCenter.automaticMode")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-switch", {
                                    staticClass: "pa-0 ma-0 ml-4",
                                    attrs: {
                                      id: "mode",
                                      color: "primary",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.walletModeTrueFalse,
                                      callback: function ($$v) {
                                        _vm.walletModeTrueFalse = $$v
                                      },
                                      expression: "walletModeTrueFalse",
                                    },
                                  }),
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading.allRetrieve,
                                        expression: "loading.allRetrieve",
                                      },
                                    ],
                                    attrs: {
                                      size: "24",
                                      width: "2",
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex align-center justify-end px-0",
                              attrs: { cols: "6" },
                            },
                            [
                              false
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.hasBalanceCanRetrieve,
                                        text: "",
                                        color: "primary",
                                        height: "36",
                                        width: "79",
                                      },
                                      on: {
                                        click:
                                          _vm.openRetrieveAllWalletBalanceDialog,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.recycleAll")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "divider" }),
                ],
                1
              ),
              _vm.walletLoading
                ? [
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.walletModeTrueFalse,
                            expression: "!walletModeTrueFalse",
                          },
                        ],
                        staticClass: "pb-4 pt-0",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mb-1",
                                attrs: { id: "transfer-area" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-2 px-0 pb-0",
                                    attrs: { cols: "5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openSelectListDialog("from")
                                      },
                                    },
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "pb-2",
                                      attrs: {
                                        filled: "",
                                        outlined: "",
                                        readonly: "",
                                        dense: "",
                                        "max-height": "36",
                                        "append-icon": "mdi-chevron-right",
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: _vm.inputDisplay.from,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inputDisplay,
                                            "from",
                                            $$v
                                          )
                                        },
                                        expression: "inputDisplay.from",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "wallet-balance caption primary--text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currencySymbol_ +
                                                _vm.walletBalance.from
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "pt-2 px-0 d-flex align-start justify-center",
                                    attrs: { cols: "2" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "chang__wallet",
                                        attrs: { icon: "" },
                                        on: { click: _vm.changWallet },
                                      },
                                      [
                                        _c("Icon", {
                                          staticClass: "primary--text",
                                          attrs: {
                                            data: require("@icon/arrow__right.svg"),
                                            width: "16",
                                            height: "8",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-2 px-0 pb-0",
                                    attrs: { cols: "5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openSelectListDialog("to")
                                      },
                                    },
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "pb-2",
                                      attrs: {
                                        "hide-details": "",
                                        filled: "",
                                        readonly: "",
                                        outlined: "",
                                        "max-height": "36",
                                        dense: "",
                                        "append-icon": "mdi-chevron-right",
                                      },
                                      model: {
                                        value: _vm.inputDisplay.to,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.inputDisplay, "to", $$v)
                                        },
                                        expression: "inputDisplay.to",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "wallet-balance caption primary--text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currencySymbol_ +
                                                (_vm.walletBalance.to === 0
                                                  ? "0.00"
                                                  : _vm.walletBalance.to)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                model: {
                                  value: _vm.valid,
                                  callback: function ($$v) {
                                    _vm.valid = $$v
                                  },
                                  expression: "valid",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 pr-1 d-flex pb-1",
                                        attrs: {
                                          id: "transfer__amount",
                                          cols: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-text-field",
                                          {
                                            attrs: {
                                              onkeyup:
                                                "this.value=this.value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                              type: "number",
                                              outlined: "",
                                              "max-height": "36",
                                              dense: "",
                                              "single-line": "",
                                              rules: [
                                                _vm.rules.required,
                                                _vm.rules.overZero,
                                                _vm.rules.overMaxBalance,
                                              ],
                                              placeholder:
                                                _vm.$t("form.missAmount"),
                                              inputmode: "decimal",
                                            },
                                            model: {
                                              value: _vm.form.amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "amount",
                                                  _vm._n(
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                )
                                              },
                                              expression: "form.amount",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "append" },
                                                slot: "append",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "showHand__btn",
                                                    attrs: {
                                                      outlined: "",
                                                      color: "primary",
                                                      dark: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.form.amount =
                                                          _vm.walletBalance.from
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "primary--text caption",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "global.showHand"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-0 pt-0",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loading.transferBtn,
                                              disabled: !_vm.valid,
                                              block: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.walletTransfer(
                                                  _vm.form
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "btnText--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("global.transfer")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider", { staticClass: "divider" }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pt-0", attrs: { cols: "12" } },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "subtitle-2 title--text font-weight-mudium",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("global.vendor") +
                                      _vm.$t("global.wallet")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "v-container",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.hasBalanceCanRetrieve,
                                        expression: "hasBalanceCanRetrieve",
                                      },
                                    ],
                                    staticClass: "px-1 py-1",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-center flex-column",
                                        attrs: {
                                          height: "74",
                                          color: _vm.transferWallertAll
                                            ? "secondary"
                                            : "card1",
                                          disabled:
                                            _vm.walletBalanceTotal < 0.01,
                                        },
                                        on: { click: _vm.recycleWalletBtn },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "line-height-12" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption font-weight-bold",
                                                class: _vm.transferWallertAll
                                                  ? "black--text"
                                                  : "subtitle--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("global.all")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "line-height-16" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "subtitle-2",
                                                class: _vm.transferWallertAll
                                                  ? "black--text"
                                                  : "primary--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.currencySymbol_ +
                                                        _vm.moneySpec(
                                                          Number(
                                                            _vm.walletBalanceTotal
                                                          )
                                                        )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.walletList, function (game, index) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: game.wallet_code,
                                      staticClass: "px-1 py-1",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "p-relative",
                                          class:
                                            game.balance > 0
                                              ? "cursor-pointer"
                                              : "",
                                          attrs: {
                                            height: "74",
                                            color: _vm.activeWallet[
                                              game.wallet_code
                                            ]
                                              ? "secondary"
                                              : "card1",
                                            disabled: game.balance < 0.01,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ToggleAddToTransferWallertList(
                                                game.wallet_code,
                                                game.balance,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column align-center justify-center h-74 px-0",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "line-height-12",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption font-weight-bold",
                                                              class: _vm
                                                                .activeWallet[
                                                                game.wallet_code
                                                              ]
                                                                ? "black--text"
                                                                : "subtitle--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    game.wallet
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "line-height-16",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "subtitle-2 font-weight-bold",
                                                              class: [
                                                                !_vm
                                                                  .activeWallet[
                                                                  game
                                                                    .wallet_code
                                                                ] &&
                                                                game.balance > 0
                                                                  ? "primary--text"
                                                                  : "subtitle--text",
                                                                {
                                                                  "black--text":
                                                                    _vm
                                                                      .activeWallet[
                                                                      game
                                                                        .wallet_code
                                                                    ],
                                                                },
                                                              ],
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.currencySymbol_ +
                                                                      _vm.moneySpec(
                                                                        Number(
                                                                          game.balance
                                                                        )
                                                                      )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm.transferWalletList.length > 0
                              ? _c(
                                  "v-row",
                                  { staticClass: "p-sticky bottom-0 bg" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-1",
                                        attrs: { cols: "5" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              block: "",
                                              color: "card1",
                                              height: "44",
                                            },
                                            on: { click: _vm.clearSeleted },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "title--text subtitle-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("global.deselect")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "px-1",
                                        attrs: { cols: "7" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              block: "",
                                              color: "primary",
                                              height: "44",
                                            },
                                            on: {
                                              click: _vm.openMutiRetrieveDialog,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "btnText--text subtitle-2 font-weight-medium",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "myCenter.batchRecycle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.transferWallertAll,
                                    expression: "transferWallertAll",
                                  },
                                ],
                                staticClass: "p-sticky bottom-0 bg",
                              },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "px-1", attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          block: "",
                                          color: "card1",
                                          height: "44",
                                        },
                                        on: {
                                          click: _vm.cancelRecycleWalletBtn,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "title--text subtitle-1 font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("global.deselect")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "px-1", attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          block: "",
                                          color: "primary",
                                          height: "44",
                                        },
                                        on: {
                                          click:
                                            _vm.openRetrieveAllWalletBalanceDialog,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "btnText--text subtitle-1 font-weight-medium",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("myCenter.recycle")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-col",
                      { staticClass: "text-center mt-10" },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            size: 70,
                            width: 4,
                            color: "primary",
                            indeterminate: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "SelectList",
        { attrs: { status: _vm.selectDialog.status } },
        [
          _c(
            "v-row",
            {
              staticClass:
                "p-sticky bg top-0 px-3 text-h6 title--text font-weight-medium",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("v-col", {}, [
                _vm._v(
                  " " + _vm._s(_vm.$t("myCenter.selectVendorWallet")) + " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _c(
                "v-col",
                {
                  class: [
                    {
                      secondary:
                        _vm.form[`${_vm.selectDialog.type}_walletcode`] ===
                        "player_balance",
                    },
                    "rounded",
                  ],
                  attrs: { cols: "12" },
                  on: {
                    click: function ($event) {
                      return _vm.setWalletCode({
                        walletCode: "player_balance",
                        name: _vm.player.name,
                        type: _vm.selectDialog.type,
                      })
                    },
                  },
                },
                [
                  _c("div", { staticClass: "px-3" }, [
                    _vm._v(" " + _vm._s(_vm.player.name) + " "),
                  ]),
                ]
              ),
              _vm._l(_vm.selectDialog.list, function (game) {
                return _c(
                  "v-col",
                  {
                    key: game.wallet_code,
                    class: [
                      {
                        secondary:
                          _vm.form[`${_vm.selectDialog.type}_walletcode`] ===
                          game.wallet_code,
                      },
                      "rounded",
                    ],
                    attrs: { cols: "12" },
                    on: {
                      click: function ($event) {
                        return _vm.setWalletCode({
                          walletCode: game.wallet_code,
                          name: game.wallet.name,
                          type: _vm.selectDialog.type,
                        })
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "px-3" }, [
                      _vm._v(" " + _vm._s(game.wallet.name) + " "),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.dialog.status, width: 280, height: 140 } },
        [
          _vm.dialog.type === "allRetrieve"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "px-6",
                    attrs: { slot: "text" },
                    slot: "text",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("flashMessage.allWalletTransferToMainWallet")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", text: "" },
                    on: { click: _vm.resetDialog },
                    slot: "btn",
                  },
                  [
                    _c("span", { staticClass: "subtitle--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " "),
                    ]),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", text: "", color: "primary" },
                    on: { click: _vm.retrieveAllWalletBalance },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                ),
              ]
            : _vm._e(),
          _vm.dialog.type === "retrieve"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "px-6 text-text",
                    attrs: { slot: "text" },
                    slot: "text",
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("flashMessage.recycleAll")) + " "
                    ),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", text: "" },
                    on: { click: _vm.resetDialog },
                    slot: "btn",
                  },
                  [
                    _c("span", { staticClass: "subtitle--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " "),
                    ]),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", text: "", color: "primary" },
                    on: { click: _vm.mutiRetrieve },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                ),
              ]
            : _vm._e(),
          _vm.dialog.type === "transfer"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "px-6",
                    attrs: { slot: "text" },
                    slot: "text",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("flashMessage.otherWalletsHaveBalances")
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setUserMode({ wallet_type: "single" })
                      },
                    },
                    slot: "btn",
                  },
                  [
                    _c("span", { staticClass: "subtitle--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " "),
                    ]),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "btn", text: "", color: "primary" },
                    on: { click: _vm.resetDialog },
                    slot: "btn",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }